import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import NotyfContext from '../../Context/NotyfContext';

import { Axios } from '../../config';
import { useAppStore } from '../../store';

export default function Header() {
  const updateAuth = useAppStore.use.updateAuth();
  const setLoading = useAppStore.use.updateLoading();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  const btLogin = async () => {
    ReactGA.event({
      category: username,
      action: 'login',
      label: 'label',
      value: '',
    });
    setLoading(true);
    await Axios.post(
      '/login',
      {
        username: username,
        password: password,
      },
      { withCredentials: true }
    )
      .then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        }
        if (!response.data.auth) {
          notyf.success(response.data.message);
          updateAuth(false);
        } else {
          updateAuth(true);
          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('auth', response.data.auth);
          updateAuth(response.data.auth);
          navigate('/logged');
          notyf.success('Logged');
          setLoading(false);
        }
        form.resetFields();
      })
      .catch(() => {
        notyf.open({
          type: 'warning',
          message: 'Vérifier vôtre mot de passe !!',
        });
        setLoading(false);
        form.resetFields();
        navigate('');
      });
  };

  const [form] = Form.useForm();

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  return (
    <header role="banner" className="flex flex-col">
      <div className="flex absolute shadow-md 2xl:px-80 lg:px-40 sm:px-0">
        <Form
          className=""
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          form={form}
        >
          <div className="flex">
            <Form.Item
              label="Nom"
              name="nom"
              style={{ margin: '1rem' }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={username === '' ? 'Surname' : username}
                type="text"
                onChange={(e) => {
                  setUserName(e.target.value.toUpperCase());
                }}
              />
            </Form.Item>
            <Form.Item
              label="Mot de passe"
              name="password"
              style={{
                margin: '1rem',
              }}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Item>
            <div className="flex justify-end fixed 2xl:right-96 lg:right-40 md:right-0 sm:right-0">
              <Space>
                <Form.Item
                  style={{
                    margin: '1rem',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    ghost
                    onClick={() => {
                      btLogin();
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </div>
          {/* <div>
            <h2>Width: {windowWidth.current}</h2>
            <h2>Height: {windowHeight.current}</h2>
          </div> */}
        </Form>
      </div>
    </header>
  );
}
