import { Button, Form, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import Logged from '../../components/Header/Logged';
import CardAddMdp from './CardAddMdp';

import { Axios } from '../../config';
import NotyfContext from '../../Context/NotyfContext';
import { useAppStore } from '../../store';

import '../../../src/style_tailwind.css';

export default function Password() {
  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);
  const updateLoading = useAppStore.use.updateLoading();
  const notyf = useContext(NotyfContext);

  const [passwordadd, setPasswordAdd] = useState('');
  const [password, setPassword] = useState([]);
  const [company, setCompany] = useState([]);
  const [companylist, setCompanyList] = useState([]);
  const [buttonpopupCardAddMdp, setButtonPopupCardAddMdp] = useState(false);
  const [updatecde, setUpdateCde] = useState('');
  const [valitem, setValItem] = useState('');

  useEffect(() => {
    Axios.get('/api/get_company').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setCompanyList(response.data);
      }
    });

    Axios.get('api/get_password').then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setPassword(response.data);
      }
    });

    setUpdateCde(0);
    updateLoading(false);
  }, [updatecde, updateLoading, notyf]);

  async function onFinishCardAddMdp(values) {
    await Axios.post('/api/insertmdp', {
      marque: values.marque,
      appareil: values.appareil,
      type: values.type,
      iduser: values.iduser,
      password: values.passwordadd,
      site: values.site,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setPasswordAdd([
          ...passwordadd,
          {
            marque: values.marque,
            appareil: values.appareil,
            type: values.type,
            iduser: values.iduser,
            password: values.password,
            site: values.site,
          },
        ]);
        setUpdateCde('1');
        setButtonPopupCardAddMdp(false);
        form.resetFields();
        notyf.success(response.data.message);
      }
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const btAjout = (val) => {
    const company = companylist.sort((a, b) => (a.num > b.num ? 1 : -1));
    setCompany(company);
    setButtonPopupCardAddMdp(true);
    setValItem(val);
  };

  const onFinishFailed = (errorFields) => {
    if (errorFields) {
      notyf.open({ type: 'error', message: `${errorFields}` });
    }
  };

  const [form] = Form.useForm();

  return (
    <>
      <Logged />
      <main className="overflow-hidden place-items-center">
        <Form {...layout} name="control-ref" form={form}>
          <div className="flex flex-col shadow-xl max-w-6xl">
            <div className="flex px-80">
              <div className="flex flex-row bg-blue-spie m-2">
                <div className="flex bg-white m-3 border-2 border-blue-spie">
                  {access > 2 ? (
                    <Space wrap>
                      <Button
                        onClick={() => {
                          btAjout(valitem);
                        }}
                      >
                        Ajout Mot de passe
                      </Button>
                    </Space>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row bg-blue-spie m-2">
              <div className="flex flex-col justify-start bg-white p-2">
                <table className="table-fixed border">
                  <caption className="caption-top shadow-xl bg-blue-spie flex p-2">
                    <h4 className="text-white text-base">
                      <b>LISTE DES MOTS DE PASSE</b>
                    </h4>
                  </caption>
                  <div className="place-items-center">
                    <thead>
                      <tr>
                        <th className="w-32 text-blue-spie text-base">
                          MARQUE
                        </th>
                        <th className="w-32 text-blue-spie text-base">
                          APPAREIL
                        </th>
                        <th className="w-32 text-blue-spie text-base">TYPE</th>
                        <th className="w-32 text-blue-spie text-base">
                          UTILISATEUR
                        </th>
                        <th className="w-32 text-blue-spie text-base">
                          MOT DE PASSE
                        </th>
                        <th className="w-32 text-blue-spie text-base">SITE</th>
                      </tr>
                    </thead>
                    {password
                      .sort((a, b) => (a.marque > b.marque ? 1 : -1))
                      .map((val, index) => {
                        return (
                          <tbody className="align-middle" key={index}>
                            <tr className="border-t-2">
                              <td className="w-32 text-center">{val.marque}</td>
                              <td className="w-32 text-center">
                                {val.appareil}
                              </td>
                              <td className="w-32 text-center">{val.type}</td>
                              <td className="w-32 text-center">{val.iduser}</td>
                              <td className="w-32 text-center">
                                {val.password}
                              </td>
                              <td className="w-32 text-center">{val.site}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </div>
                </table>
              </div>
            </div>
          </div>
        </Form>
        {buttonpopupCardAddMdp === true ? (
          <CardAddMdp
            setPassword={setPasswordAdd}
            // onNumChange={onNumChange}
            onFinish={onFinishCardAddMdp}
            onFinishFailed={onFinishFailed}
            company={company}
            setButtonPopup={setButtonPopupCardAddMdp}
          />
        ) : (
          <></>
        )}
      </main>
    </>
  );
}
