import { Button, Form, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import '../../../src/style.css';
import PopupDate from './PopupDate';

import '../../../src/style_tailwind.css';

export default function CardMatAttrib({
  matattrib,
  btupdateValid,
  onFinishFailed,
  setUpdateValid,
  setAttestId,
  username,
}) {
  const [buttonpopupdate, setButtonPopupDate] = useState(false);

  const [form] = Form.useForm();

  const buttondate = (val) => {
    setButtonPopupDate(true);
    setAttestId(val);
  };
  const onReset = () => {
    form.resetFields();
    setButtonPopupDate(false);
  };

  const btupdateValidform = (data) => {
    btupdateValid(data);
    setButtonPopupDate(false);
  };

  return (
    <div className="flex items-center justify-center m-2">
      <div className="flex flex-col shadow-xl justify-start bg-white p-2">
        <table className="table-auto  separate">
          <caption className="caption-top shadow-xl bg-blue-spie flex p-2">
            <b className="text-white align-middle text-base">
              LISTE DE VOS MATERIELS
            </b>
          </caption>
          <thead className="flex w-full">
            <tr className="border-red-900">
              <th className="text-blue-spie text-base">ID</th>
              <th className="text-blue-spie text-base">MARQUE</th>
              <th className="text-blue-spie text-base">DESIGNATION</th>
              <th className="text-blue-spie text-base">SN</th>
              <th className="w-28 text-blue-spie text-base">VALIDITE</th>
              <th className="text-blue-spie text-base">ACTION</th>
            </tr>
          </thead>
          {matattrib
            .sort((a, b) => (a.spie > b.spie ? 1 : -1))
            .map((val, index) => {
              var now = moment();
              var nowAlarm = moment().add(90, 'd');
              var valid = val.rev;
              var dateLimit = moment(valid);
              return (
                <tbody className="flex" key={index}>
                  {val.userid === username ? (
                    <tr className="border-t-2 text-center">
                      <td className="">{val.spie}</td>
                      <td className="">{val.num}</td>
                      <td className="">{val.design}</td>
                      <td className="">{val.sn}</td>
                      <td className="w-28 place-items-center">
                        {dateLimit <= now ? (
                          <div className=" w-20 limitAlert px-1">
                            {dateLimit.format('DD-MM-YYYY')}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= now && dateLimit <= nowAlarm ? (
                          <div className="w-20 limitAlarm px-1">
                            {dateLimit.format('DD-MM-YYYY')}
                          </div>
                        ) : (
                          <></>
                        )}
                        {dateLimit >= nowAlarm ? (
                          <div className="w-20 limit px-1">
                            {dateLimit.format('DD-MM-YYYY')}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="">
                        <Form>
                          <Space wrap className="">
                            <Button
                              type="primary"
                              size="small"
                              ghost
                              onClick={() => {
                                buttondate(val.id);
                              }}
                            >
                              Date
                            </Button>
                          </Space>
                        </Form>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              );
            })}
        </table>
      </div>
      {buttonpopupdate === true ? (
        <PopupDate
          onReset={onReset}
          onFinishFailed={onFinishFailed}
          setUpdateValid={setUpdateValid}
          btupdateValid={btupdateValid}
          onFinish={btupdateValidform}
        ></PopupDate>
      ) : (
        <></>
      )}
    </div>
  );
}
