import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const createSelectors = (_store) => {
  let store = _store;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    store.use[k] = () => store((s) => s[k]);
  }
  return store;
};

export const useAppStore = createSelectors(
  create(
    devtools(
      persist((set) => ({
        auth: false,
        updateAuth(auth) {
          set({ auth: auth });
        },
        loading: false,
        updateLoading(loading) {
          set({ loading: loading });
        },
      }))
    )
  )
);
