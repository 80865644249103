import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import '../../../src/style.css';
import Logged from '../../components/Header/Logged';
import { Axios } from '../../config';

import { useAppStore } from '../../store';
import LoadAnim from '../LoadAnim';

import '../../../src/style_tailwind.css';

const { Option } = Select;

function Codes() {
  const loading = useAppStore.use.loading();
  const updateLoading = useAppStore.use.updateLoading();
  const [ansi, setAnsi] = useState([]);
  const [searchcol, setSearchcol] = useState('');
  const [ansilist, setAnsilist] = useState('');
  const [iec61850list, setIec61850list] = useState('');

  useEffect(() => {
    updateLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get('/api/get_ansi').then((response) => {
        setAnsi(response.data);
      });
      updateLoading(true);
    },
    [updateLoading]
  );

  const handleChangeCol = (value) => {
    setSearchcol(value);
  };

  const onSearch = (value) => {
    setAnsilist(value);
    setIec61850list(value);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <>
      <Logged />
      <main className="overflow-hidden mt-4 place-items-center">
        <Form {...layout} name="control-ref" form={form}>
          <div className="flex flex-col m-2 shadow-xl max-w-6xl">
            <div className="flex flex-row m-2">
              <div className="flex flex-col justify-start bg-white p-2">
                <table className="table-fixed border">
                  <caption className="caption-top shadow-lg bg-blue-spie flex p-2">
                    <h4 className="text-white text-base">
                      <b>LISTE DES CODES</b>
                    </h4>
                  </caption>
                  {loading === true ? (
                    <LoadAnim size="large" />
                  ) : (
                    <div className="flex m-1 p-2">
                      <Form.Item
                        name="tri"
                        label="Option de tri"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Select
                          placeholder="Select By:"
                          onChange={handleChangeCol}
                          style={{ width: 250 }}
                        >
                          <Option value="ansi">ANSI</Option>
                          <Option value="iec61850">IEC61850</Option>
                          <Option value="tous">TOUS...</Option>
                        </Select>
                      </Form.Item>
                      <>
                        {searchcol === 'ansi' ? (
                          <Form.Item
                            name="ansi"
                            style={{
                              margin: '0.5rem',
                              marginLeft: '2rem',
                            }}
                          >
                            <Select
                              showSearch
                              placeholder="Select a code"
                              onChange={onSearch}
                              style={{ width: 200 }}
                            >
                              {ansi.map((val, index) => (
                                <Select.Option key={index} value={val.ansi}>
                                  {val.ansi}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                        {searchcol === 'iec61850' ? (
                          <Form.Item
                            name="iec61850"
                            style={{
                              margin: '0.5rem',
                              marginLeft: '2rem',
                            }}
                          >
                            <Select
                              showSearch
                              placeholder="Select a code"
                              onChange={onSearch}
                              style={{ width: 200 }}
                            >
                              {ansi.map((val, index) => (
                                <Select.Option key={index} value={val.iec61850}>
                                  {val.iec61850}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  )}
                  <div className="place-items-center">
                    <thead>
                      <tr>
                        <th className="w-32 text-blue-spie text-base">ANSI</th>
                        <th className="w-32 text-blue-spie text-base">
                          IEC61850
                        </th>
                        <th className="w-32 text-blue-spie text-base">
                          IEC60617
                        </th>
                        <th className="w-80 text-blue-spie text-base">
                          DESIGNATION FR
                        </th>
                        <th className="w-80 text-blue-spie text-base">
                          DESIGNATION EN
                        </th>
                      </tr>
                    </thead>
                    {ansi
                      .filter((val) => {
                        if (searchcol === 'ansi' && ansilist.includes(val.ansi))
                          if (ansilist !== '') {
                            return val;
                          }
                        if (
                          searchcol === 'iec61850' &&
                          iec61850list.includes(val.iec61850)
                        )
                          if (iec61850list !== '') {
                            return val;
                          }
                        if (searchcol === 'tous') {
                          return val;
                        }
                        return false;
                      })
                      .map((val, index) => {
                        return (
                          <tbody className="" key={index}>
                            <tr className="border-t-2">
                              <td className="w-32 text-center">{val.ansi}</td>
                              <td className="w-32 text-center">
                                {val.iec61850}
                              </td>
                              <td className="w-32 text-center">
                                {val.iec60617}
                              </td>
                              <td className="w-80 text-center">
                                {val.designFr}
                              </td>
                              <td className="w-80 text-center">
                                {val.designEn}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </div>
                </table>
              </div>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
}

export default Codes;
