import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Logged from '../../components/Header/Logged';
import { Axios } from '../../config';

import moment from 'moment';
import { useAppStore } from '../../store';
import LoadAnim from '../LoadAnim';

import '../../../src/style_tailwind.css';

const { Option } = Select;

function Clearances() {
  const loading = useAppStore.use.loading();
  const updateLoading = useAppStore.use.updateLoading();
  const [attest, setAttest] = useState([]);
  const [searchcol, setSearchcol] = useState('');
  const [userslist, setUserslist] = useState([]);
  const [user, setUser] = useState([]);
  const [client, setClientlist] = useState([]);

  useEffect(
    (notyf) => {
      Axios.get('/api/get_attest').then((response) => {
        setAttest(response.data);
      });

      Axios.get('/api/get_users').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setUserslist(response.data);
        }
      });

      Axios.get('/api/get_clients').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setClientlist(response.data);
        }
      });

      updateLoading(false);
    },
    [updateLoading]
  );

  const handleChangeCol = (value) => {
    setSearchcol(value);
    const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
    setUser(user);
  };

  const onSearch = (value) => {
    setUserslist(value);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const clientslist = client.sort((a, b) =>
    a.client_name > b.client_name ? 1 : -1
  );

  const filteredType = attest.reduce(
    (unique, item) =>
      unique.includes(item.type) ? unique : [...unique, item.type],
    []
  );

  const [form] = Form.useForm();

  return (
    <>
      <Logged />
      <main className="overflow-hidden mt-4 place-items-center">
        <Form {...layout} name="control-ref" form={form}>
          <div className="flex flex-col m-2 shadow-xl max-w-6xl">
            <div className="flex m-2">
              <div className="flex flex-col justify-start bg-white P-2">
                <table className="table-fixed border">
                  <caption className="caption-top shadow-lg bg-blue-spie flex p-2">
                    <h4 className="text-white text-base">
                      <b>LISTE DES TITRES D'HABILITATIONS</b>
                    </h4>
                  </caption>
                  {loading === true ? (
                    <LoadAnim size="large" />
                  ) : (
                    <div className="flex m-1 p-2 px-48">
                      <Form.Item
                        label="Sélectionner une option de tri"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Select
                          placeholder="Select By:"
                          onChange={handleChangeCol}
                          style={{ width: 250, marginRight: 10 }}
                        >
                          <Option value="userid">NOM</Option>
                          <Option value="data1">CLIENT</Option>
                          <Option value="type">TYPE</Option>
                          <Option value="tous">TOUS...</Option>
                        </Select>
                      </Form.Item>
                      <>
                        {searchcol === 'userid' ? (
                          <Form.Item
                            name="userid"
                            style={{
                              margin: '0.5rem',
                            }}
                          >
                            <Select
                              showSearch
                              placeholder="Select a name"
                              onChange={onSearch}
                              style={{ width: 200, marginRight: 10 }}
                            >
                              {user.map((val, index) => (
                                <Select.Option key={index} value={val.lastname}>
                                  {val.lastname}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                        {searchcol === 'data1' ? (
                          <Form.Item
                            name="data1"
                            style={{
                              margin: '0.5rem',
                            }}
                          >
                            <Select
                              showSearch
                              placeholder="Select a factory"
                              onChange={onSearch}
                              style={{ width: 200, marginRight: 10 }}
                            >
                              {clientslist.map((val, index) => (
                                <Select.Option
                                  key={index}
                                  value={val.client_name + ' ' + val.lieu}
                                >
                                  {val.client_name + ' ' + val.lieu}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                        {searchcol === 'type' ? (
                          <Form.Item
                            name="type"
                            style={{
                              margin: '0.5rem',
                            }}
                          >
                            <Select
                              showSearch
                              placeholder="Select a type"
                              onChange={onSearch}
                              style={{ width: 200, marginRight: 10 }}
                            >
                              {filteredType.map((val, index) => (
                                <Select.Option key={index} value={val}>
                                  {val}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  )}
                  <p className="place-items-center">
                    <thead>
                      <tr>
                        <th className="w-32 text-blue-spie text-base">NOM</th>
                        <th className="w-32 text-blue-spie text-base">
                          DESIGNATION
                        </th>
                        <th className="w-32 text-blue-spie text-base">
                          TITRE / CLIENT
                        </th>
                        <th className="w-28 text-blue-spie text-base">
                          VALIDITE
                        </th>
                      </tr>
                    </thead>
                    {attest
                      .filter((val) => {
                        if (
                          searchcol === 'userid' &&
                          userslist.includes(val.userid)
                        ) {
                          if (userslist !== '') {
                            return val;
                          }
                        } else if (
                          searchcol === 'data1' &&
                          userslist.includes(val.data1)
                        ) {
                          if (userslist !== '') {
                            return val;
                          }
                        } else if (
                          searchcol === 'type' &&
                          userslist.includes(val.type)
                        ) {
                          if (userslist !== '') {
                            return val;
                          }
                        } else if (searchcol === 'tous') {
                          if (userslist !== '') {
                            return val;
                          }
                        }
                        return false;
                      })
                      .sort((a, b) => (a.userid > b.userid ? 1 : -1))
                      .map((val, index) => {
                        var now = moment();
                        var nowAlarm = moment().add(90, 'd');
                        var valid = val.valid;
                        var dateLimit = moment(valid);

                        return (
                          <tbody className="align-middle" key={index}>
                            <tr className="border-t-2">
                              <td className="py-1 text-center">{val.userid}</td>
                              <td className="text-center">{val.type}</td>
                              <td className="text-center">{val.data1}</td>
                              <td className="w-28 place-items-center">
                                {dateLimit <= now ? (
                                  <p className="w-20 limitAlert px-1">
                                    {dateLimit.format('DD-MM-YYYY')}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {dateLimit >= now && dateLimit <= nowAlarm ? (
                                  <p className="w-20 limitAlarm px-1">
                                    {dateLimit.format('DD-MM-YYYY')}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {dateLimit >= nowAlarm ? (
                                  <p className="w-20 limit px-1">
                                    {dateLimit.format('DD-MM-YYYY')}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </p>
                </table>
              </div>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
}

export default Clearances;
