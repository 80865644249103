import { Form } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import { Axios } from '../../config';

import Logged from '../../components/Header/Logged';
import NotyfContext from '../../Context/NotyfContext';
import { useAppStore } from '../../store';
import LoadAnim from '../LoadAnim';
import CardAdd from './CardAdd';
import CardGestion from './CardGestion';
import CardInfo from './CardInfo';
import CardMovStock from './CardMovStock';
import CardUpdate from './CardUpdate';

import '../../../src/style_tailwind.css';

export default function Tools() {
  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);
  const loading = useAppStore.use.loading();
  const notyf = useContext(NotyfContext);

  const [designation, setDesignation] = useState([]);
  const [designlist, setDesignlist] = useState([]);
  const [idlist, setIdlist] = useState([{}]);
  const [userslist, setUserslist] = useState([]);
  const [loanlist, setLoanlist] = useState([]);
  const [user, setUser] = useState([]);
  const [supplierlist, setSupplierlist] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [searchcol, setSearchcol] = useState('');
  const [searchspie, setSearchspie] = useState('');
  const [searchsn, setSearchsn] = useState('');
  const [update, setUpdate] = useState('');
  const [updateid, setUpdateId] = useState('');
  const [updatecde, setUpdateCde] = useState('');
  const [popupinfo, setPopupInfo] = useState(false);
  const [popupstock, setPopupStock] = useState(false);
  const [popupupdate, setPopupUpdate] = useState(false);
  const [valitem, setValItem] = useState('');
  const [buttonpopupcardadd, setButtonPopupcardadd] = useState(false);

  useEffect(
    (notyf) => {
      Axios.get('/api/get').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setIdlist(response.data);
        }
      });

      Axios.get('/api/get_users').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setUserslist(response.data);
        }
      });

      Axios.get('/api/get_supplier').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setSupplierlist(response.data);
        }
      });

      Axios.get('/api/get_loan').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setLoanlist(response.data);
        }
      });

      Axios.get('/api/get_design').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setDesignlist(response.data);
        }
      });

      setUpdateCde(0);
    },
    [updatecde]
  );

  async function onFinishCardAdd(data) {
    await Axios.post('/api/insert', {
      spie: data.spie,
      num: data.num,
      ref: data.ref,
      design: data.design,
      obs: data.obs,
      sn: data.sn,
      rev: data.rev.format('YYYY-MM-DD'),
      userid: data.userid,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setIdlist([
          ...idlist,
          {
            spie: data.spie,
            num: data.num,
            ref: data.ref,
            design: data.design,
            obs: data.obs,
            sn: data.sn,
            rev: data.rev.format('YYYY-MM-DD'),
            userid: data.userid,
          },
        ]);
        setUpdateCde('1');
        form.resetFields();
        notyf.success(response.data.message);
      }
    });
  }

  async function btdeleteId(val) {
    await Axios.delete(`/api/delete/${val.id}`).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdateCde('1');
        notyf.success('Item supprimé de la BDD');
      }
    });
  }

  async function btupdateId(id) {
    await Axios.put('/api/update', {
      id: id,
      updateid: updateid,
      update: update,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdateId('');
        setUpdate('');
        setUpdateCde('1');
        notyf.success(`Objet modifié`);
      }
    });
  }

  async function btupdateLoanId(id, loanid, userid) {
    await Axios.put('/api/updateloanid', {
      id: id,
      updateuserid: userid,
      updateloanid: loanid,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setUpdateCde('1');
      }
    });
  }

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const onSetRevChange = (val) => {
    setUpdate(val.format('YYYY-MM-DD'));
  };

  const onSearch = (value) => {
    setSearchspie(value);
    form.resetFields();
  };

  const onSearchsn = (value) => {
    setSearchsn(value);
  };

  const handleChangeCol = (value) => {
    setSearchcol(value);

    const supplier = supplierlist.sort((a, b) =>
      a.supplier_name > b.supplier_name ? 1 : -1
    );
    setSupplier(supplier);

    const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
    setUser(user);

    const designation = designlist.sort((a, b) =>
      a.design > b.design ? 1 : -1
    );
    setDesignation(designation);
  };

  const handleChangeUpdateId = (value) => {
    setUpdateId(value);
  };

  const handleChangeUser = (value) => {
    setUpdate(value);
  };

  const [form] = Form.useForm();

  const btInfo = (val) => {
    setPopupInfo(true);
    setValItem(val);
  };

  const btStock = (val) => {
    setPopupStock(true);
    setUpdateCde('1');
    setValItem(val);
  };
  const btUpdate = (val) => {
    setPopupUpdate(true);
    setValItem(val);
  };
  const btDelete = (val) => {
    btdeleteId(val);
  };
  const btAjout = (val) => {
    const supplier = supplierlist.sort((a, b) =>
      a.supplier_name > b.supplier_name ? 1 : -1
    );
    setSupplier(supplier);

    const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
    setUser(user);
    setButtonPopupcardadd(true);
    setValItem(val);
  };
  const onReset = () => {
    form.resetFields();
    setPopupStock(false);
    setPopupUpdate(false);
    setPopupInfo(false);
  };

  const onFinishFailed = (errorFields) => {
    if (errorFields) {
      notyf.open({ type: 'error', message: `${errorFields}` });
    }
  };

  return (
    <>
      <Logged />
      <main className="overflow-hidden place-items-center">
        {/* GESTION */}
        <CardGestion
          loading={loading}
          LoadAnim={LoadAnim}
          searchcol={searchcol}
          searchspie={searchspie}
          searchsn={searchsn}
          onSearch={onSearch}
          supplier={supplier}
          setSupplier={setSupplier}
          supplierylist={supplierlist}
          setSupplierlist={setSupplierlist}
          user={user}
          setUser={setUser}
          userslist={userslist}
          onSearchsn={onSearchsn}
          access={access}
          idlist={idlist}
          setIdlist={setIdlist}
          btInfo={btInfo}
          btStock={btStock}
          btUpdate={btUpdate}
          btDelete={btDelete}
          updatecde={updatecde}
          handleChangeCol={handleChangeCol}
          loanlist={loanlist}
          setButtonPopup={setButtonPopupcardadd}
          btAjout={btAjout}
          valitem={valitem}
          designation={designation}
        />
        {/* ADD */}
        {buttonpopupcardadd === true ? (
          <CardAdd
            access={access}
            onFinishFailed={onFinishFailed}
            onFinish={onFinishCardAdd}
            supplier={supplier}
            user={user}
            setButtonPopup={setButtonPopupcardadd}
          />
        ) : (
          <></>
        )}
        {/* Card Update */}
        {popupupdate === true ? (
          <CardUpdate
            handleChangeUpdateId={handleChangeUpdateId}
            updateid={updateid}
            update={update}
            setUpdate={setUpdate}
            onSetRevChange={onSetRevChange}
            handleChangeUser={handleChangeUser}
            userslist={userslist}
            btupdateId={btupdateId}
            valitem={valitem}
            onReset={onReset}
          />
        ) : (
          <></>
        )}
        {/* Card Info */}
        {popupinfo === true ? (
          <CardInfo valitem={valitem} onReset={onReset} loanlist={loanlist} />
        ) : (
          <></>
        )}
        {/* Card Mouvement Stock */}
        {access > 0 && popupstock === true ? (
          <CardMovStock
            dateFormatList={dateFormatList}
            btupdateLoanId={btupdateLoanId}
            valitem={valitem}
            setLoanlist={setLoanlist}
            setUpdateCde={setUpdateCde}
            user={user}
            onReset={onReset}
            loanlist={loanlist}
            onFinishFailed={onFinishFailed}
            idlist={idlist}
          />
        ) : (
          <></>
        )}
      </main>
    </>
  );
}
