import React from 'react';
import Logged from '../../components/Header/Logged';

import '../../../src/style_tailwind.css';

function Site() {
  return (
    <>
      <Logged />
      <main className="overflow-hidden mt-4 place-items-center">
        <div className="flex flex-col shadow-2xl max-w-6xl">
          <div className="flex m-2 bg-blue-spie">
            <ul className="w-96">
              <li className="openFirstLevel">
                <a
                  role="button"
                  className=""
                  aria-expanded="false"
                  href="https://bassin-lacq.accueil-secu.fr/login"
                >
                  <h4 className="text-white text-base shadow-xl">
                    SOBEGI / ARKEMA (Bassin-lacq.accueil)
                  </h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex m-2 bg-blue-spie">
            <ul className="w-96">
              <li className="openFirstLevel">
                <a
                  role="button"
                  className="openFirstLevelButton"
                  aria-expanded="false"
                  href="https://live.runmyprocess.com/pub/112501509958900690/appli/380769?P_mode=LIVE"
                >
                  <h4 className="text-white text-base shadow-xl">
                    TEREGA Lussagnet (Acceuil Sécurité)
                  </h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex m-2 bg-blue-spie">
            <ul className="w-96">
              <li className="openFirstLevel">
                <a
                  role="button"
                  className="openFirstLevelButton"
                  aria-expanded="false"
                  href="https://esafety-prod.airliquide.com/auth/login"
                >
                  <h4 className="text-white text-base shadow-xl">
                    AIR LIQUIDE (E.Safety Tous sites)
                  </h4>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
}
export default Site;
